<!-- 接入weburl到iframe里呈现 -->
<template>
  <iframe :src="fullUrl">
    react-iframe
  </iframe>
</template>

<script>
import qs from "qs";
import base from "@/api/base";

export default {
  name: 'VWeburlIframe',
  props: {
    url: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    fullUrl() {
      const str = qs.stringify({
        next: this.url + '?' + qs.stringify(this.params),
        token: window.localStorage.getItem("token"),
        campusId: window.localStorage.getItem('campusid'),
        ...this.params
      })
      const host = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_UMI_HOST : process.env.VUE_APP_PROD_UMI
      return host + '/umi/' + this.url + '?' + qs.stringify(this.params)
      return host + '/umi/login?' + str + '&_time=' + (new Date().getTime())
    }
  }
}
</script>

<style scoped lang="scss">
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
